/* -----------------------------------Catalog--------------------------------- */
.table-file {
  text-align: center;
  margin-bottom: 40px;
}
.title-catalog {
  font-weight: bold;
  text-align: center;
  font-size: 2.2rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* ------------------------------------Home-------------------------------- */
.title-home {
  font-weight: bold;
  text-align: center;
  font-size: 2.2rem;
  margin-top: 60px;
}

.description-home {
  width: 100%;
  height: 430px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: lighter;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.container-flex-wrap-reverse {
  display: flex;
  flex-wrap: wrap-reverse !important;
  margin-bottom: 90px;
}
.padding-founder-home {
  padding-right: 30px;
}
.container-flex-popular {
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 20px;
}

.slick-prev:before,
.slick-next:before {
  color: #ad3235;
}
.product-list {
  margin-left: 10px;
  margin-top: 10px;
}

.container-flex-button-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cfcdca;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ad3235;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #75191a;
}

.container-flex-menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 30px;
  margin-bottom: 90px;
}

.img-size-popular {
  height: 280px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container-menu-home {
  flex-direction: column;
  width: 25%;
}

.container-menu-home > div {
  width: 100%;
}

.image-menu {
  width: 100%;
  height: 340px;
  object-fit: cover;
  filter: brightness(50%);
}
.bg-guitar-menu {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}
.text-guitar-menu {
  font-size: 3rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-padding-1 {
  padding-right: 5px;
}
.menu-padding-2 {
  padding-right: 3px;
  padding-top: 3px;
}
.menu-padding-3 {
  padding-left: 3px;
  padding-bottom: 3px;
}
.menu-padding-4 {
  padding-left: 3px;
  padding-top: 3px;
}
.button-color {
  background-color: #ad3235;
  border-color: #ad3235;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
  font-size: 0.8rem;
  padding: 12px 32px;
}
/* .button-color:hover {
  background-color: #ad3235;
  border-color: #ad3235;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 0.9rem;
  padding: 12px 32px;
} */

.button-color-popular {
  background-color: #ad3235;
  border-color: #ad3235;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  padding: 12px 32px;
}
/* .button-color-popular:hover {
  background-color: #ad3235;
  border-color: #ad3235;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.1rem;
  padding: 12px 32px;
} */

.vdo-present-home {
  bottom: 55%;
  position: absolute;
  width: 100% !important;
}

.vdo-present-home-cover {
  position: relative;
  width: 100% !important;
  height: 400px !important;
  overflow: hidden !important;
  margin-bottom: 90px;
}

.body-gallery-home {
  height: auto;
  overflow: hidden;
}

/* ---------------------------------------Product-------------------------------- */
.item-filter {
  display: block;
}
.container-flex-product {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
}

.title-filter {
  text-align: center;
  padding-top: 15px;
  font-weight: bold;
  color: #fff;
  background-color: #ad3235;
  max-width: 100%;
  min-width: 100px;
  height: 50px;
}
.list-filter {
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-left: 20px;
}
.title-header {
  font-weight: bold;
  text-align: center;
  font-size: 2.2rem;
  margin-top: 2rem;
}

.img-size-product {
  height: auto;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}
.img-size-product-default {
  margin-left: auto;
  margin-right: auto;
  width: 230px;
  height: 250px;
}
.card-body-product {
  border: none;
  background-color: #ece7de;
  text-align: center;
  margin-bottom: 10px;
  overflow: hidden;
  width: auto;
}
.filter-margin {
  padding-left: 120px !important;
}
.filter-price-container {
  display: flex;
}
/* ------------------------------------Detail Product------------------------- */
.icon-into-image {
  margin-right: 5px;
  font-size: 1.3rem;
  color: #fff;
  -webkit-text-stroke: 1px black;
}
.send-order-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  top: 0;
}
.label-order {
  margin-top: 3px;
  font-size: 1.2rem;
  margin-right: 8px;
  white-space: nowrap;
}

.send-message-container {
  position: relative;
}

.fb-order {
  position: absolute !important;
}

.line-btn {
  position: absolute !important;
  left: 170px;
  margin-bottom: 10px;
  vertical-align: top;
  top: 0;
  margin-right: 20px;
  height: 32px;
}
.font-style-name {
  font-weight: bold;
  font-size: 2.2rem;
}
.font-style-price {
  font-weight: bold;
  margin-left: 10px;
  font-size: 2rem;
  color: red;
}
.font-style-description {
  white-space: pre-line;
  margin-top: 10px;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.big-image-show {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.big-image-rotate-show {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.style-video {
  width: 450px !important;
  height: 250px !important;
}

.SlideImg {
  position: relative;
  text-align: center;
  width: 100%;
  height: 490px;
  cursor: pointer;
  overflow: hidden;
}
.text-show-click-in-rotate {
  position: absolute;
  top: 8px;
  right: 16px;
}
.rows {
  width: 100%;
  margin-top: 10px;
  overflow-x: scroll;
  display: inline-block;
  white-space: nowrap;
  padding-bottom: 10px;
}
.column {
  width: auto;
  height: 10vh;
  margin-left: auto;
  margin-right: 10px;
  object-fit: cover;
}
.image-360-container {
  position: relative;
  display: inline;
  top: 0;
  left: 0;
}
.icon-360 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 60%;
}
.padding-detail-1 {
  padding-right: 20px;
}
.padding-detail-2 {
  padding-left: 30px;
}
.title-overview {
  font-weight: bold;
  text-align: center;
  font-size: 2.2rem;
  margin-top: 3rem;
}
.style-table {
  width: auto;
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
  font-family: kanit;
}
.td-title {
  font-weight: bold;
}

/* ------------------------------------About Us-------------------------------- */
.image-about-home {
  border-radius: 5px;
  height: 440px;
  width: 100%;
  object-fit: contain;
}

.image-about-brand {
  border-radius: 5px;
  height: auto;
  width: 100%;
  object-fit: cover;
}

/* ------------------------------------How To Buy-------------------------------- */
.row-promotion {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 0;
  margin-right: 0;
}
.description-promotion {
  text-align: left;
  width: 90%;
  margin-top: 20px;
  margin-left: 30px; 
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: lighter;
  font-size: 1rem;
}

.image-promotion-list {
  height: 100%;
  width: 100%;
}

.button-card-style {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

/* ------------------------------------Contact-------------------------------- */
.contact-box {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.contact-map {
  height: 470px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
}

/* -----------------------------------Send Line Success -------------------------------------*/
.box-send-success-line {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  font-family: kanit;
}
/* ------------------------------------All page-------------------------------- */

.size-block {
  width: 340px;
}
.title {
  font-weight: bold;
  text-align: center;
  font-size: 2.2rem;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}

.title-type {
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
}
.description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: lighter;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 15px;
}

.links:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.white-navigation {
  position: block;
  background: #fff !important;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding-left: 50px;
}

.white-navigation div {
  font-family: kanit;
  color: #000;
  font-size: 1rem;
  margin-left: 3px;
  padding-top: 12px;
}

.card-body {
  border: none;
  background-color: #ece7de;
  text-align: center;
  padding: 0px;
}

.card-body-size {
  margin-right: 15px;
  width: 15rem;
  margin-bottom: 10px;
  overflow: hidden;
}
/* --------------------------------------------------------------------------------- */
/*จอใหญ่มาก*/
@media screen and (min-width: 1281px) {
}

/*จอใหญ่*/
@media screen and (max-width: 1280px) and (min-width: 960px) {
}

/*จอกลาง*/
@media screen and (max-width: 960px) and (min-width: 501px) {
  .vdo-present-home {
    bottom: 5%;
    position: absolute;
    width: 100% !important;
  }

  .vdo-present-home-cover {
    position: relative;
    width: 100% !important;
    height: 400px !important;
    overflow: hidden !important;
    margin: 0 auto;
  }
  .description-home {
    font-size: 1rem;
  }
  .image-guitar-menu-1 {
    height: 340px;
  }

  .image-guitar-menu-2 {
    height: 300px;
  }

  .text-guitar-menu {
    font-size: 1.8rem;
  }
  /* ---------------------------------------Product-------------------------------- */
  .size-block-product {
    width: 50% !important;
  }
  .img-size-product {
    width: 180px;
  }
  .img-size-product-default {
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    height: 250px;
  }
  .filter-margin {
    padding-left: 0px !important;
  }

  /* ------------------------------------Detail Product------------------------- */

  .style-video {
    width: 100% !important;
    height: 380px !important;
  }
  .label-order {
    font-size: 1.6rem;
  }
  .font-style-name {
    font-weight: bold;
    font-size: 2.8rem;
  }
  .font-style-price {
    font-weight: bold;
    margin-left: 0px;
    font-size: 2.5rem;
    color: red;
  }
  .font-style-description {
    white-space: pre-line;
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .big-image-show {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .SlideImg {
    width: 100%;
    height: 380px;
    overflow: hidden;
  }
  .rows {
    width: 100%;
  }
  .column {
    height: 10vh;
    margin-left: auto;
    margin-right: 10px;
    object-fit: cover;
  }
  .padding-detail-1 {
    margin-right: 20px;
    padding-right: 60px;
    width: 100% !important;
  }
  .padding-detail-2 {
    margin-top: 30px;
    padding-left: 0px;
    width: 100% !important;
  }
  .style-table {
    font-size: 1.3rem;
    margin-left: auto;
    margin-right: auto;
    font-family: kanit;
  }

  /* ------------------------------------Home-------------------------------- */
  .container-flex-popular {
    margin-right: 15px;
    margin-left: 15px;
  }
}

/*จอเล็ก*/
@media screen and (max-width: 500px) {
  /* -----------------------------------Catalog--------------------------------- */
  .table-file {
    text-align: center;
    margin-bottom: 30px;
  }
  .title-catalog {
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  /* ------------------------------------Home-------------------------------- */
  .bg-guitar-menu {
    margin-top: 5px;
  }
  .img-size-product {
    width: 100%;
  }
  .container-flex-popular {
    margin-right: 15px;
    margin-left: 15px;
  }
  .title-home {
    font-size: 2rem;
    margin-top: 20px;
  }
  .container-flex-wrap-reverse {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 40px;
  }
  .container-flex-menu {
    margin-bottom: 40px;
  }
  .container-flex-button-center {
    margin-bottom: 40px;
  }
  .padding-founder-home {
    padding-right: 0px;
  }
  .description-home {
    font-size: 0.95rem;
  }

  .image-about-home {
    border-radius: 5px;
    height: 400px;
    width: 100%;
    object-fit: contain;
  }

  .button-color {
    font-size: 0.7rem;
    padding: 10px 25px;
  }

  .image-menu {
    height: 150px;
  }

  .text-guitar-menu {
    font-size: 1.8rem;
  }

  .container-menu-home {
    width: 100%;
  }

  .menu-padding-1 {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 6px;
  }
  .menu-padding-2 {
    padding-right: 0px;
    padding-top: 6px;
  }
  .menu-padding-3 {
    padding-left: 0px;
    padding-bottom: 0px;
    padding-top: 6px;
  }
  .menu-padding-4 {
    padding-left: 0px;
    padding-top: 6px;
  }

  /* ---------------------------------------Product-------------------------------- */
  .item-filter {
    display: none;
  }
  .card-body-product {
    margin-left: auto;
    margin-right: auto;
  }
  .size-block-product {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .filter-margin {
    padding-left: 0px !important;
  }
  /* ------------------------------------Detail Product------------------------- */
  .send-order-container {
    margin-bottom: 0px;
  }
  .style-video {
    width: 100% !important;
    height: 210px !important;
  }
  .style-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .font-style-name {
    font-weight: bold;
    font-size: 1.8rem;
  }
  .font-style-price {
    font-weight: bold;
    margin-left: 0px;
    font-size: 1.5rem;
    color: red;
  }

  .fb-order {
    position: inherit !important;
    margin-bottom: 15px;
  }

  .line-btn {
    position: inherit !important;
  }
  .font-style-description {
    white-space: pre-line;
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .label-order {
    font-size: 1rem;
  }
  .big-image-show {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .SlideImg {
    width: 100%;
    height: 550px;
    overflow: hidden;
  }
  .rows {
    width: 90vw;
    margin-top: 5px;
  }
  .column {
    height: 10vh;
    margin-right: 10px;
    object-fit: cover;
  }
  .padding-detail-1 {
    padding-right: 0px;
  }
  .padding-detail-2 {
    margin-top: 30px;
    padding-left: 10px;
  }
  .title-overview {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  .style-table {
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-family: kanit;
  }

  /* ------------------------------------About Us-------------------------------- */
  .image-about-brand {
    height: auto;
  }
  /* ---------------------------------- How To Buy ------------------------------- */
  .description-promotion {
    width: 90%;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 50px; 
    font-size: 1rem;
  }
  /* ------------------------------------ Contact -------------------------------- */
  .contact-map {
    height: 300px;
  }
  /* --------------------------------- Send Line Success -------------------------*/
  .box-send-success-line {
    height: 300px;
  }
  /* ------------------------------------All Page------------------------------- */
  .white-navigation {
    padding-left: 10px;
  }
  .size-block {
    width: 100%;
  }
}
